var XD = function(){

    var interval_id,
    last_hash,
    cache_bust = 1,
    attached_callback,
    window = this;

    return {
        postMessage : function(message, target_url, target) {

            if (!target_url) { 
                return; 
            }

            target = target || parent;  // default to parent

            if (window['postMessage']) {
                // the browser supports window.postMessage, so call it with a targetOrigin
                // set appropriately, based on the target_url parameter.
                target['postMessage'](message, target_url.replace( /([^:]+:\/\/[^\/]+).*/, '$1'));

            } else if (target_url) {
                // the browser does not support window.postMessage, so set the location
                // of the target to target_url#message. A bit ugly, but it works! A cache
                // bust parameter is added to ensure that repeat messages trigger the callback.
                target.location = target_url.replace(/#.*$/, '') + '#' + (+new Date) + (cache_bust++) + '&' + message;
            }
        },

        receiveMessage : function(callback, source_origin) {
            // browser supports window.postMessage
            if (window['postMessage']) {
                // bind the callback to the actual event associated with window.postMessage
                if (callback) {
                    attached_callback = function(e) {
                        //console.log(source_origin, e.origin);
                        if ((typeof source_origin === 'string' && e.origin !== source_origin)
                        || (Object.prototype.toString.call(source_origin) === "[object Function]" && source_origin(e.origin) === !1)) {
                            return !1;
                        }
                        callback(e);
                    };
                }
                if (window['addEventListener']) {
                    window[callback ? 'addEventListener' : 'removeEventListener']('message', attached_callback, !1);
                } else {
                    window[callback ? 'attachEvent' : 'detachEvent']('onmessage', attached_callback);
                }
            } else {
                // a polling loop is started & callback is called whenever the location.hash changes
                interval_id && clearInterval(interval_id);
                interval_id = null;

                if (callback) {
                    interval_id = setInterval(function(){
                        var hash = document.location.hash,
                        re = /^#?\d+&/;
                        if (hash !== last_hash && re.test(hash)) {
                            last_hash = hash;
                            callback({data: hash.replace(re, '')});
                        }
                    }, 100);
                }
            }
        }
    };
}();
window.BTP = window.BTP || (function () {
    var arrDomReadyCallBacks = [] ;
    function excuteDomReadyCallBacks(){
       for (var i=0; i < arrDomReadyCallBacks.length; i++) {
         arrDomReadyCallBacks[i]();
       }
       arrDomReadyCallBacks = [] ;
    }

    return function (callback){
        arrDomReadyCallBacks.push(callback);
        /* Mozilla, Chrome, Opera */
        if (document.addEventListener ) {
            document.addEventListener('DOMContentLoaded', excuteDomReadyCallBacks, false);
        }
        /* Safari, iCab, Konqueror */
        if (/KHTML|WebKit|iCab/i.test(navigator.userAgent)) {
            browserTypeSet = true ;
            var DOMLoadTimer = setInterval(function () {
                if (/loaded|complete/i.test(document.readyState)) {
                    //callback();
                    excuteDomReadyCallBacks();
                    clearInterval(DOMLoadTimer);
                }
            }, 10);
        }
        /* Other web browsers */

        window.onload = excuteDomReadyCallBacks;
    }
})();

BTP.CallRequestWidget = function (global, XD) {

    var BUTTONSTYLE = [
        'button.call-request',
        '{',
            'display: block;',
            'position: absolute;',
            'right: 50px;',
            'top: -50px;',
            'padding: 30px;',
            'border-radius: 40px;',
            'border: 1px solid #047aad;',
            'background-color: #009fe3;',
            'background-image: url(//cdn2.iconfinder.com/data/icons/font-awesome/1792/phone-512.png);',
            'background-size: 70%;',
            'background-repeat: no-repeat;',
            'background-position: center;',
            'cursor: pointer;',
            'outline: none;',
            'transition: all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275);',
        '}',
        'button.call-request:hover',
        '{',
            'transform: scale(1.2);',
        '}',
        'button.call-request.scroll',
        '{',
            'transform: rotate(15deg);',
        '}',
    ].join('');

    function compareNodeAttr(queryStr, targetNode, attr) {
        return !!(targetNode.getAttribute(attr) || '').split(' ').find(function(cl) {
            var reg = new RegExp('^'+queryStr+'$');
            return reg.test(cl);
        });
    }
    var Util = {
        extendObject: function(a, b) {
            for(prop in b){
                a[prop] = b[prop];
            }
            return a;
        },
        proto: 'https:' == document.location.protocol ? 'https://' : 'http://',
        create: function(name, options) {
            var elem, content;
            elem = document.createElement(name);
            if(options.style) elem.style = options.style;
            if(content = options.content) {
                if (!Array.isArray(content)) content = [content];
                content.map(function(el) {
                    if (typeof el === 'string') el = document.createTextNode(el);
                    return elem.appendChild(el);
                });
            }
            if(typeof options.attrs === 'object') {
                Object.keys(options.attrs).forEach(function(key) {
                    elem.setAttribute(key, options.attrs[key]);
                });
            }
            return elem;
        },
        test: function(query, target) {
            if(typeof query === 'string') {
                switch (query.slice(0,1)) {
                case '.': return compareNodeAttr(query.slice(1), target, 'class');
                case '#': return compareNodeAttr(query.slice(1), target, 'id');
                default: return compareNodeAttr(query, target, 'id');
                }
            } else {
                return false;
            }
        },
        genID: function(key) {
            return key + '_' + Math.random().toString().slice(2);
        }
    };

    function Widget (target, options) {
        this.target = this._parse(target);
        this.name = 'widget';
        this.domain = 'rutp.ru';
        this.path = "/widgets/";
        this.created = false;
        this.widgetElement = null;
        this.iframe = null;
        this.iframeId = Util.genID(this.name + '_iframe'),
        this.containerId = Util.genID(this.name + '_container');
        this.options = Util.extendObject({
            theme: 'btp', // тема оформления
            dep: '2062,2064,2065,2063' //внутренний номер для камутации
        }, options);

        XD.receiveMessage(function(message) {
            var data = {};
            try {
                data = JSON.parse(message.data);
            } catch (e) { }
            if (data.height) {
                this._resize(data.height, data.width);
            }
            if (data.close) this._hide();
        }.bind(this), (Util.proto + this.domain).replace( /([^:]+:\/\/[^\/]+).*/, '$1'));

        var _show = this._show.bind(this);
        document.body.addEventListener('click', function(e) {
            var current_target = e.target;
            while (current_target !== this) {
                if (Util.test(target, current_target)) { 
                    _show();
                    break;
                }
                current_target = current_target.parentNode;
            }
        });
        this._create();
	window.addEventListener('resize', this._resize.bind(this));
    }


    Widget.prototype.createButton = function() {
        if(this.button) return this.button;
        this.button = Util.create('button', {
            style: '',
            attrs: {
                id: this.name + '_button'
            }
        });
        document.head.appendChild(Util.create('style', {
            content: BUTTONSTYLE
        }));
        window.addEventListener('scroll', this._buttonPosition.bind(this));
        window.addEventListener('resize', this._buttonPosition.bind(this));
    };

    Widget.prototype._buttonPosition = function() {
        var offset = window.innerHeight - 100,
            el = document.querySelector('.call-request');
        el.style.top = offset + window.scrollY + 'px';
        el.classList.add('scroll');
        setTimeout(function() {
            el.classList.remove('scroll');
        }, 500)
    }

    Widget.prototype.getUrl = function(file) {
        var options = this.options;
        return String.prototype.concat(Util.proto, this.domain, this.path, file, "/?",

            Object.keys(options).map(function(key) {
                return key + '=' + options[key];
            }).join('&'),

            "#" + encodeURIComponent(document.location.href)
        );
    };
    Widget.prototype._create = function() {
        if (this.created) return;
        this.iframe = Util.create('iframe', {
            style: 'margin: auto; position: absolute',
            attrs: {
                src: this.getUrl('call.php'),
                id: this.iframeId,
                scrolling: 'no',
                width: '0',
                height: '0',
                frameborder: '0'
            },
        });
        this.widgetElement = Util.create('div', {
		style: 'position: fixed;z-index: 999;top: 0px;right: 0px;bottom: 0px;left: 0px;display: none; background-color: rgba(62, 62, 62, .09); cursor: pointer',
            attrs: {
                id: this.containerId
            },
            content: [
                Util.create('a', {
                    style: 'position: absolute; top: 20px; right: 20px; font-family: monospace; color: rgb(255, 255, 255); text-decoration: none; text-transform: none; font-size: 28px; font-weight: bolder;',
                    attrs: {
                        href: 'javascript:void(0)',
                        id: this.name + '_close'
                    },
                    content: '✖'
                }),
                this.iframe
            ]
        });
        document.body.insertBefore(this.widgetElement, document.body.lastChild);
        this.widgetElement.addEventListener('click', this._hide.bind(this));
        this.created = true;
    };



    Widget.prototype._parse = function(query) {
        if(typeof query === 'string') {
            switch (query.slice(0,1)) {
                case '.': return document.querySelector(query);
                case '#': return document.getElementById(query.slice(1));
                default: return document.getElementById(query);
            }
        } else {
            return;
        }
    };

    Widget.prototype._resize = function(height, width) {
        width = width || '100%';
        if (height > 0 && this.iframe)
        {
            this.iframe.height = height;
            this.iframe.width = width;
        }
        this.iframe.style.top = Math.round(window.innerHeight/2) - (this.iframe.clientHeight/2) + 'px';
        this.iframe.style.left = Math.round(window.innerWidth/2) - (this.iframe.clientWidth/2) + 'px';

	this.widgetElement.querySelector('a').style.top = this.iframe.offsetTop - 10 + 'px'
	this.widgetElement.querySelector('a').style.left = this.iframe.offsetLeft + this.iframe.offsetWidth + 'px'
    };

    Widget.prototype._toggle = function(e) {
        e && e.preventDefault && e.preventDefault();
        var display;
        switch(this.widgetElement.style.display) {
            case 'block': display = 'none';
            case 'none': display = 'block'
            break
            default: return;
        }
        this.widgetElement.style.display = display;
    };

    Widget.prototype._show = function(e) {
        this.widgetElement.style.display = 'block';
        XD.postMessage(JSON.stringify({
            open: true
        }), Util.proto.concat(this.domain), this.iframe.contentWindow);
    }

    Widget.prototype._hide = function(e) {
        this.widgetElement.style.display = 'none';
    }

    Widget.create = function (elem, options) {
        return new Widget(elem, options);
    }
    return Widget;
}(window, XD);

